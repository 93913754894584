import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import { NavDropdown } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import 'bootstrap/dist/css/bootstrap.min.css';
import styled from 'styled-components';
import React, { useContext } from 'react';
import { Link } from 'gatsby';
import * as styles from './topMenu.module.css';
import Search from '../Search';
import { isExecutive } from '../../lib/helpers';
import CustomContext from '../../../custom-context';
import LinkComponent from '../../utils/linkComponent';
const searchIndices = [
	{ name: `Pages`, title: `Pages` },
	{ name: `Resources`, title: `Resources` },
	{ name: `Events`, title: `Events` },
	{ name: `Offers`, title: `Offers` },
];

const StyledLink = styled((props) => <LinkComponent {...props} />)`
	font-size: 16px;
	margin-left: 1em;
	color: var(--secondary_2);
	padding: 0.3em;

	text-decoration: none;
	:hover {
		color: var(--link_color);
	}
`;

const NavStyled = styled.div`
	.navbar .navbar-nav {
		color: var(--secondary_2);
		font-size: 16px;
		align-items: center;
	}

	.navbar-nav .dropdown-menu {
		z-index: 1031;
	}

	.loginButton {
		background-color: var(--primary);
		color: var(--secondary_6);
		width: 7em;
		text-align: center;
		font-size: 16px;
		margin-left: 1em;
		padding: 0.3em;
		cursor: pointer;
	}

	.topMenu {
		/* Caudit Beige */
		background-color: var(--secondary_3);
		padding: 0;
	}

	.navbar-light .navbar-brand {
		color: var(--secondary_6);
	}

	.navbar-light .navbar-toggler {
		border-color: var(--secondary_7) !important;
		color: var(--secondary_2) !important;
	}
	.nav-link {
		margin-left: 1em;
		background-color: var(--primary);
		color: var(--secondary_8) !important;
		width: 7em;
		text-align: center;
	}
	.navbar-nav .dropdown-menu {
		background-color: var(--primary);
	}
	.nav-item.dropdown:hover .dropdown-menu {
		display: block;
	}
	.dropdown-item:focus,
	.dropdown-item:hover {
		color: var(--secondary_6);
		background-color: var(--primary);
	}

	.top-menu {
		line-height: 24px;
	}
`;
const TopMenu = (props) => {
	const { user, handleLogout } = useContext(CustomContext);
	const { menu } = props;
	return (
		<NavStyled>
			<Navbar expand='xl' variant='light' className={styles.topMenu}>
				<Container>
					<Navbar.Brand style={{ cursor: 'pointer' }}></Navbar.Brand>
					<Navbar.Toggle aria-controls='basic-navbar-nav' />
					<Navbar.Collapse id='basic-navbar-nav' className='top-menu'>
						<Nav className='ms-auto'>
							{/* <Search indices={searchIndices} /> */}
							{menu.map((item) => {
								return (
									<StyledLink to={`${item.path}`} key={item.title}>
										{item.title}
									</StyledLink>
								);
							})}
							{user !== null && user !== undefined && isExecutive(user) ? (
								<StyledLink to='/dashboard/executive'> Dashboard</StyledLink>
							) : null}
							{user !== null && user !== undefined ? (
								<div
									className='loginButton logout'
									onClick={() => {
										handleLogout();
									}}
									onKeyDown={() => {
										handleLogout();
									}}
									role='button'
									tabIndex='0'
								>
									{' '}
									Logout
								</div>
							) : (
								<NavDropdown
									align='end'
									id='nav-dropdown-basic'
									title='Login'
									variant='primary'
									renderMenuOnMount={true}
								>
									<NavDropdown.Item
										href={`${process.env.GATSBY_AAF_LOGIN_URL}`}
										className='loginButton'
										onClick={() =>
											sessionStorage.setItem('returnURL', window.location.href)
										}
									>
										Login via AAF
									</NavDropdown.Item>
									<NavDropdown.Item
										href={`${process.env.GATSBY_TUAKIRI_LOGIN_URL}`}
										className='loginButton'
										onClick={() =>
											sessionStorage.setItem('returnURL', window.location.href)
										}
									>
										Login via Tuakiri
									</NavDropdown.Item>
									<NavDropdown.Item>
										<LinkComponent className='loginButton' to='/login-help/'>
											Login Help
										</LinkComponent>
									</NavDropdown.Item>
								</NavDropdown>
							)}
						</Nav>
					</Navbar.Collapse>
				</Container>
			</Navbar>
		</NavStyled>
	);
};
export default TopMenu;
