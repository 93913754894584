import 'bootstrap/dist/css/bootstrap.min.css';
import styled from 'styled-components';
import React from 'react';
import { Row, Col, Image, Button, Container } from 'react-bootstrap';
import fBanner from '../../images/footer.png';
import { Link } from 'gatsby';
import ButtonComponent from '../../utils/ButtonComponent';
import LinkComponent from '../../utils/linkComponent';
import { StaticImage } from 'gatsby-plugin-image';

const NewsletterStyled = styled.div`
	padding-top: 3rem;
	.newsletterContent {
		justify-content: center;
		display: flex;
		flex-direction: column;
		// align-items: flex-start;
		padding: 1em 7em;
	}

	.description {
		margin-bottom: 1em;
		color: var(--secondary_2);
	}

	h2 {
		color: var(--primary) !important ;
		//font-size: 2.5rem;
		font-size: var(--font-size-lg);
	}

	.connect {
		text-align: right;
		margin-top: 1rem;
		margin-bottom: 1rem;
	}

	.fBanner {
		width: 28em;
		float: left;
	}

	.bannerCol {
		padding: 0;
	}

	@media (max-width: 768px) {
		padding-top: 1rem;
		.newsletterContent {
			padding: 3em;
		}
		h2 {
			font-size: 2rem;
		}

		.connect {
			text-align: left;
			margin-top: 1rem;
			margin-bottom: 1rem;
		}
		.fBanner {
			width: 18em;
		}
	}
`;
const Newsletter = (data) => {
	return (
		<NewsletterStyled>
			<Container style={{ backgroundColor: 'var(--secondary_3)' }} fluid>
				<Row>
					<Col xs={12} md={5} lg={5} className='bannerCol'>
						<StaticImage
							src='../../images/footer.png'
							className='fBanner'
							alt='Connect with CAUDIT'
							loading='lazy'
						/>
					</Col>
					<Col xs={12} md={7} lg={7} className='newsletterContent'>
						<div>
							<h2>Connect with CAUDIT</h2>
						</div>
						<div className='description'>
							Subscribe to our newsletter and hear about the latest trends and
							new resources.
						</div>
						<div>
							<LinkComponent
								href='http://www.vision6.com.au/em/forms/subscribe.php?db=459519&s=161961&a=52260&k=b91caaa'
								target='_blank'
								onClick={() => {
									window.analytics.track('Link Clicked', {
										page_name: data.props.title,
										title: 'Connect with CAUDIT',
										static_component_title: 'Newsletter Panel',
										link_name: 'Subscribe',
										link: 'http://www.vision6.com.au/em/forms/subscribe.php?db=459519&s=161961&a=52260&k=b91caaa',
										quick_links_title: null,
										path: window.location.pathname,
										item_title: null,
										report_type: 'New',
										collection_type: null,
										url: window.location.href,
									});
								}}
							>
								<ButtonComponent
									analyticsTrackTitle='Newsletter Sign Up Clicked'
									analyticsTitle='Newsletter Sign Up'
									windowLocationHref={
										typeof window !== 'undefined' && window.location.href
									}
									buttonName='Join'
									iconVisible={false}
								></ButtonComponent>
							</LinkComponent>
						</div>
						{/* <Form>
							<Form.Group>
								<Form.Control
									type='email'
									placeholder='Enter email address'
									className='emailField'
								/>
							</Form.Group>
						</Form> */}
						<div className='connect'>
							<LinkComponent
								target='_blank'
								rel='https://caudit.edu.au'
								href='https://cauditevents.wufoo.com/forms/contact-us/'
								onClick={() => {
									window.analytics.track('Link Clicked', {
										page_name: data.props.title,
										title: 'Connect with CAUDIT',
										static_component_title: 'Newsletter Panel',
										link_name: 'Subscribe',
										link: 'http://www.vision6.com.au/em/forms/subscribe.php?db=459519&s=161961&a=52260&k=b91caaa',
										quick_links_title: null,
										path: window.location.pathname,
										item_title: null,
										report_type: 'New',
										collection_type: null,
										url: window.location.href,
									});
								}}
							>
								or contact a member of our team{' '}
							</LinkComponent>
						</div>
					</Col>
				</Row>
			</Container>
		</NewsletterStyled>
	);
};
export default Newsletter;
