import React, { useState, useEffect } from 'react';
import jwt_decode from 'jwt-decode';
import { navigate } from 'gatsby';
import Layout from './src/components/Layout/layout';
export const isBrowser = () => typeof window !== 'undefined';

const CustomContext = React.createContext({});

export function CustomProvider(props) {
	const [user, setUser] = useState(null);
	const [token, setToken] = useState(null);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const queryString = window.location.search;
		const urlParams = new URLSearchParams(queryString);
		if (urlParams.has('user') || getCookie('token')) {
			handleUserStateChange();
		} else {
			setLoading(false);
		}
	}, []);

	//get the cookie by name and return it
	function getCookie(name) {
		const value = `; ${document.cookie}`;
		const parts = value.split(`; ${name}=`);
		if (parts.length === 2) return parts.pop().split(';').shift();
	}

	// handle the user state on each Auth action and also on page refreshes
	const handleUserStateChange = async () => {
		var cookieToken = getCookie('token');
		let activeUser = user;

		//get the user object from the url parameters
		const queryString = window.location.search;
		const urlParams = new URLSearchParams(queryString);
		const userVar = urlParams.get('user');

		if (urlParams.has('user')) {
			setToken(userVar);
			sessionStorage.setItem('token', userVar);
			document.cookie = `token=${userVar}; path=/`;
			var returnURL = sessionStorage.getItem('returnURL');

			try {
				activeUser = jwt_decode(userVar);
				if (activeUser.exp < Date.now() / 1000) throw 'Error: Expired';
				// set the user in state
				window.analytics?.identify(activeUser.id, {
					name: activeUser.firstName + ' ' + activeUser.lastName,
					email: activeUser.email,
					userRoles: activeUser.userRoles,
					memberInstitute: activeUser.memberInstitute,
				});

				//remove query parameter from url
				const url = new URL(window.location);
				url.searchParams.delete('user');
				window.history.replaceState(null, null, url);
				setUser(activeUser);
				window.analytics.track(`Logged in from URL`, {
					loggedInFromURL: returnURL,
					action: 'Clicked',
					location: window.location.href,
				});
			} catch (error) {
				console.log(error);
			}

			if (
				returnURL !== 'null' &&
				returnURL !== 'undefined' &&
				returnURL !== null &&
				returnURL !== undefined &&
				returnURL !== process.env.GATSBY_WEBSITE_URL
			) {
				returnURL = new URL(returnURL).pathname + new URL(returnURL).search;
				navigate(returnURL);
			}

			// set loading to false
			sessionStorage.setItem('returnURL', null);
			setLoading(false);
		} else if (cookieToken) {
			// set the user session
			try {
				activeUser = jwt_decode(cookieToken);
				if (activeUser.exp < Date.now() / 1000) throw 'Error: Expired';
				setToken(cookieToken);
				window.analytics?.identify(activeUser.id, {
					name: activeUser.firstName + ' ' + activeUser.lastName,
					email: activeUser.email,
					userRoles: activeUser.userRoles,
					memberInstitute: activeUser.memberInstitute,
				});
				setUser(activeUser);
			} catch (error) {
				console.log(error);
				sessionStorage.setItem('token', null);
			}
		} else {
			setLoading(false);
		}
	};

	// handle logout action
	const handleLogout = () => {
		sessionStorage.setItem('token', null);
		// clear the cookie
		document.cookie = 'token=;path=/;';
		setToken(null);
		setUser(null);
	};

	return (
		<CustomContext.Provider
			value={{ user, setUser, loading, token, setToken, handleLogout }}
		>
			<Layout {...props}></Layout>
		</CustomContext.Provider>
	);
}

export default CustomContext;
