import * as Sentry from '@sentry/gatsby';
import Vimeo from '@vimeo/player';
import '@fontsource/raleway';
import '@fontsource/heebo';
import '@fontsource/heebo/200.css';
import '@fontsource/heebo/300.css';
import '@fontsource/heebo/500.css';
import '@fontsource/heebo/600.css';
import '@fontsource/heebo/700.css';
import 'bootstrap/dist/css/bootstrap.min.css';
const { CustomProvider } = require('./custom-context');
const React = require('react');

export const onRouteUpdate = ({ location, prevLocation }) => {
	var iframes = document.querySelectorAll('iframe');
	try {
		iframes.forEach((iframe) => {
			var player = new Vimeo(iframe);
			window.analytics.ready(function () {
				var VimeoAnalytics = window.analytics.plugins.VimeoAnalytics;
				window.analytics.register(VimeoAnalytics);
				var vimeoAnalytics = new VimeoAnalytics(
					player,
					'c7ab22279d452aabcbdc96c2fc28ffc9'
				);
				vimeoAnalytics.initialize();
			});
		});
	} catch (error) {
		console.log(error);
	}
	if (location?.pathname !== prevLocation?.pathname) {
		// ignore this function if the path has the scroll hash identifier
		// check for a location.hash value and whether it is starting with #
		// if it does, return from the function
		if (location?.hash?.startsWith('#')) {
			return;
		}
		window?.scrollTo(0, 0);
		//scroll to the top banner
		document
			?.getElementById('bodyChildren')
			?.scrollIntoView({ behavior: 'smooth' });
		document
			?.getElementById('gatsby-focus-wrapper')
			?.scrollIntoView({ behavior: 'smooth' });
	}
};

export function wrapPageElement({ element, props }) {
	return <CustomProvider {...props}>{element}</CustomProvider>;
}
